<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Terminos y condiciones de uso</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">

            <!-- <p class="text-muted text-justify">El presente Política de Privacidad establece los términos en que Solunes Digital usa y protege la información que es
            proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de
            los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser
            identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta
            Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar
            continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>

            <h5 class="card-title">Información que es recogida</h5>

            <p class="text-muted text-justify">Nuestro sitio web podrá recoger información personal por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica
            para procesar algún pedido o realizar una entrega o facturación.</p>

            <h5 class="card-title">Uso de la información recogida</h5>

            <p class="text-muted text-justify">Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para
            mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios. Es posible
            que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos
            y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos
            correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.</p>

            <p class="text-muted text-justify">Solunes Digital está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>

            <h5 class="card-title">Cookies</h5>

            <p class="text-muted text-justify">Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su
            ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y
            también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web
            pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.</p>

            <p class="text-muted text-justify" >Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta
            información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente.
            Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar
            un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted
            así lo quiera y la proporcione directamente, visitas a una web . Usted puede aceptar o negar el uso de cookies, sin
            embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También
            usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda
            utilizar algunos de nuestros servicios.</p>

            <h5 class="card-title">Enlaces a Terceros</h5>

            <p class="text-muted text-justify">Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en
            estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no
            somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos
            sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar
            que usted está de acuerdo con estas.</p>

            <h5 class="card-title" >Control de su información personal</h5>

            <p class="text-muted text-justify">En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o
            desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir
            nuestro boletín o publicidad usted puede cancelarla en cualquier momento.</p>

            <p class="text-muted text-justify">Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>
            <p class="text-muted text-justify">Solunes Digital Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier
            momento. </p> -->

            <p class="text-muted text-justify ">En <strong>Solunes Digital</strong>, valoramos y respetamos la privacidad de nuestros usuarios. Esta Política de Privacidad detalla cómo
            recopilamos, utilizamos, protegemos y manejamos la información que nos proporcionas al usar nuestra aplicación o sitio
            web. Recomendamos revisar periódicamente esta página para estar informado sobre cualquier actualización de esta
            política.</p>
            <h5 class="card-title" >1. Información que recopilamos</h5>

            <p class="text-muted text-justify">Podemos recopilar la siguiente información personal cuando interactúas con nuestra aplicación o sitio web:</p>

            <ul class="text-muted text-justify">
              <li><strong>Datos personales:</strong> Nombre, dirección de correo electrónico, información de contacto y datos demográficos.</li>
              <li><strong>Ubicación:</strong> Información de geolocalización en tiempo real, cuando otorgues el permiso correspondiente.</li>
              <li><strong>Información específica:</strong> En casos necesarios, solicitaremos información adicional para procesar pedidos, realizar entregas o emitir facturación.</li>
            </ul>

            <h5 class="card-title" >2. Uso de la información</h5>

            <p class="text-muted text-justify">Utilizamos la información recopilada con los siguientes fines:</p>

            <ul class="text-muted text-justify">
              <li>Proveer servicios personalizados y mejorar nuestra oferta.</li>
              <li>Registrar usuarios, gestionar pedidos y coordinar servicios de transporte.</li>
              <li>Utilizar la geolocalización en tiempo real para:
                <ul>
                  <li>Identificar tu ubicación actual y asignar el vehículo más cercano.</li>
                  <li>Proveer rutas y tiempos estimados de llegada.</li>
                  <li>Mejorar la precisión y efectividad de nuestros servicios.</li>
                </ul>
              </li>
              <li>Enviar correos electrónicos con ofertas, novedades o información relevante para ti. Estos correos pueden ser cancelados en cualquier momento desde el enlace proporcionado en ellos.</li>
              <li>Realizar análisis internos para mejorar nuestros productos y servicios.</li>
            </ul>

            <p class="text-muted text-justify" ><strong>Seguridad de los datos:</strong> Implementamos sistemas avanzados de seguridad para proteger tu información contra accesos no autorizados, y mantenemos nuestros sistemas actualizados constantemente.</p>

            <h5 class="card-title" >3. Uso de cookies</h5>

            <p class="text-muted text-justify" >Las cookies son pequeños archivos que se almacenan en tu dispositivo para personalizar tu experiencia en nuestro sitio web. Estas cookies nos permiten:</p>

            <ul class="text-muted text-justify">
              <li>Analizar el tráfico web y optimizar la experiencia del usuario.</li>
              <li>Ofrecer un servicio personalizado basado en tus visitas anteriores.</li>
            </ul>

            <p class="text-muted text-justify">Puedes aceptar, rechazar o eliminar cookies desde la configuración de tu navegador. Sin embargo, desactivarlas puede limitar ciertas funcionalidades del sitio web</p>

            <h5 class="card-title" >4. Permiso de geolocalización</h5>
            
            <p class="text-muted text-justify">Nuestra aplicación solicita acceso a tu ubicación en tiempo real para brindar servicios de transporte. Este acceso permite:</p>

            <ul class="text-muted text-justify">
              <li>Ubicar tu posición para asignar conductores o servicios cercanos.</li>
              <li>Calcular rutas y tiempos estimados de llegada.</li>
              <li>Brindar información de seguimiento en tiempo real de tu viaje.</li>
            </ul>

            <p class="text-muted text-justify">La geolocalización solo se activa con tu consentimiento explícito. Puedes gestionar este permiso desde la configuración de tu dispositivo en cualquier momento. No compartimos tu ubicación con terceros, salvo que sea necesario para cumplir
            con los servicios contratados o por requerimiento legal.</p>

            <h5 class="card-title" >5. Enlaces a sitios de terceros</h5>
            <p class="text-muted text-justify">Nuestra aplicación o sitio web puede incluir enlaces a otros sitios de interés. Al hacer clic en estos enlaces y
            abandonar nuestra plataforma, Solunes Digital no tiene control sobre las políticas de privacidad o seguridad de esos sitios terceros. Te recomendamos revisar sus políticas de privacidad antes de compartir cualquier dato.</p>

            <h5 class="card-title">6. Control de tu información personal</h5>

            <p class="text-muted text-justify">Tienes derecho a limitar el uso y la recopilación de tu información personal. Puedes:</p>

            <ul class="text-muted text-justify">
              <li>Modificar tus preferencias al completar formularios, eligiendo no recibir comunicaciones promocionales.</li>
              <li>Cancelar tu suscripción a correos electrónicos desde el enlace incluido en cada mensaje.</li>
              <li>Gestionar o revocar permisos, como el acceso a tu ubicación, desde la configuración de tu dispositivo.</li>
            </ul>
            <p class="text-muted text-justify"><strong>Nota:</strong> Nunca venderemos, cederemos ni compartiremos tu información personal sin tu consentimiento explícito, salvo que sea requerido por ley.</p>

            <h5 class="card-title" >7. Cambios a esta Política de Privacidad</h5>
            <p class="text-muted text-justify"><strong>Solunes Digital</strong> se reserva el derecho de actualizar esta política en cualquier momento. Las modificaciones serán publicadas en esta página y entrarán en vigencia de inmediato. Te recomendamos consultarla periódicamente.</p>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->