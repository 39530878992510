<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Política de Privacidad</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <!-- <h5 class="card-title">Introduction :</h5> -->

            <p class="text-muted text-justify">El presente Política de Privacidad establece los términos en que Solunes Digital usa y protege la información que es
            proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de
            los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser
            identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta
            Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar
            continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>

            <h5 class="card-title">Información que es recogida</h5>

            <p class="text-muted text-justify">Nuestro sitio web podrá recoger información personal por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica
            para procesar algún pedido o realizar una entrega o facturación.</p>

            <h5 class="card-title">Uso de la información recogida</h5>

            <p class="text-muted text-justify">Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para
            mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios. Es posible
            que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos
            y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos
            correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.</p>

            <p class="text-muted text-justify">Solunes Digital está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>

            <h5 class="card-title">Cookies</h5>

            <p class="text-muted text-justify">Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su
            ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y
            también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web
            pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.</p>

            <p class="text-muted text-justify" >Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta
            información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente.
            Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar
            un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted
            así lo quiera y la proporcione directamente, visitas a una web . Usted puede aceptar o negar el uso de cookies, sin
            embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También
            usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda
            utilizar algunos de nuestros servicios.</p>

            <h5 class="card-title">Enlaces a Terceros</h5>

            <p class="text-muted text-justify">Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en
            estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no
            somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos
            sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar
            que usted está de acuerdo con estas.</p>

            <h5 class="card-title" >Control de su información personal</h5>

            <p class="text-muted text-justify">En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o
            desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir
            nuestro boletín o publicidad usted puede cancelarla en cualquier momento.</p>

            <p class="text-muted text-justify">Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>
            <p class="text-muted text-justify">Solunes Digital Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier
            momento. </p>

            <!-- <p class="text-muted text-justify">It seems that only fragments of the original text remain in the Lorem Ipsum texts used
              today. One may speculate that over the course of time certain letters were added or deleted at various
              positions within the text.</p>

            <h5 class="card-title">User Agreements :</h5>
            <p class="text-muted text-justify">The most well-known dummy text is the 'Lorem Ipsum', which is said to have <b
                class="text-danger">originated</b> in the 16th century. Lorem Ipsum is <b
                class="text-danger">composed</b> in a pseudo-Latin language which more or less <b
                class="text-danger">corresponds</b> to 'proper' Latin. It contains a series of real Latin words. This
              ancient dummy text is also <b class="text-danger">incomprehensible</b>, but it imitates the rhythm of most
              European languages in Latin script. The <b class="text-danger">advantage</b> of its Latin origin and the
              relative <b class="text-danger">meaninglessness</b> of Lorum Ipsum is that the text does not attract
              attention to itself or distract the viewer's <b class="text-danger">attention</b> from the layout.</p>
            <p class="text-muted text-justify">There is now an <b class="text-danger">abundance</b> of readable dummy texts. These
              are usually used when a text is <b class="text-danger">required purely</b> to fill a space. These
              alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or <b
                class="text-danger">nonsensical</b> stories.</p>
            <p class="text-muted text-justify">It seems that only <b class="text-danger">fragments</b> of the original text remain in
              the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were
              added or deleted at various positions within the text.</p>

            <h5 class="card-title">Restrictions :</h5>
            <p class="text-muted text-justify">You are specifically restricted from all of the following :</p> -->

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->