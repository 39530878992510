<section class="d-flex align-items-center background-effect bg-soft-primary" id="home" style="padding: 100px 0px;position: relative;">
  <div class="container z-index-1">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h4 class="display-4 fw-bold text-dark mb-4">Contáctanos</h4>
          <p class="para-desc mx-auto text-muted mb-0">¡Estamos aquí para ayudarte! Si tienes preguntas o necesitas más información, no dudes en ponerte en
          contacto con nosotros.</p>

        </div>
      </div>

      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">

        <img src="assets/images/nft/home.png" class="img-fluid" alt="">
        

      </div>
    </div>
  </div>
  <ul class="circles p-0 mb-0">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</section><!--end section-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>


<section class="section pb-0">

 <!--  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">

        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Contactarnos !</h4>
            <div class="custom-form mt-3">
              <form method="post" name="myForm" onsubmit="return validateForm()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">

                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Nombre<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input type="text" class="form-control ps-5" placeholder="Nombre completo..." name="s" required="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Correo electrónico<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input type="email" class="form-control ps-5" placeholder="Correo electrónico" name="email" required="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Mensaje</label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5" placeholder="Escribir..."></textarea>
                      </div>
                    </div>
                  </div>

                </div>
                
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary">Enviar</button>
                    </div>
                  </div>
                  
                </div>
                
              </form>
            </div>
            
          </div>
        </div>

        
      </div>


      <div class="col-lg-7 col-md-6 order-1 order-md-2 custom">
        <div class="app-feature-shape-left ">
          <div class="text-center text-md-start">
            <div class="card border-0 text-center features feature-primary feature-clean d-flex item-content-center justify-content-center" style="height: 400px;">
              <div class="icons text-center mx-auto">
                <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
              </div>
              <div class="content mt-4">
                <h4 class="fw-bold ">Santiago - Chile</h4>
                <p class="text-muted font-22">Alonso de Cordova 5045, Santiago</p>
                <p class="m-0 font-18">Teléfono / whatsapp: <a href="https://wa.link/dk5urn" class="text-primary">+56 938865190</a></p>
                <p class="m-0 font-18">Correo electrónico: <a href="mailto:info@solunes.com" class="text-primary">info&#64;solunes.com</a></p>


                <ng-template #content let-modal>
                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.6607849784596!2d-70.5789382!3d-33.406013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf26bdce8dcb%3A0x86ac59fedd4b3d26!2sAlonso%20de%20C%C3%B3rdova%205045%2C%207561057%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sbo!4v1732672043966!5m2!1ses-419!2sbo"
                      width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade" height="450" width="100%"></iframe>
                  </div>
                </ng-template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
 -->

 <div class="d-flex justify-content-center ">
  <div class="d-flex justify-content-center align-items-center flex-column px-4 ">
    
    <h4 class="fw-bold text-center ">Santiago - Chile</h4>
    <p class="text-center  text-muted font-22 d-flex align-items-center flex-wrap justify-content-center "><i class="uil uil-map-marker d-block rounded h3 mb-0"></i> &nbsp;&nbsp; Alonso de Cordova 5045, Santiago</p>
    <p class="text-center text-muted  font-18 d-flex align-items-center flex-wrap justify-content-center" ><i class="uil uil-phone d-block rounded h3 mb-0"></i><span>&nbsp;&nbsp; Teléfono / whatsapp: <a href="https://wa.link/dk5urn" class="text-primary"> +56 938865190</a></span> </p>
    <p class="text-center text-muted  font-18 d-flex align-items-center flex-wrap justify-content-center "><i class="uil uil-envelope d-block rounded h3 mb-0"></i><span>&nbsp;&nbsp; Correo electrónico: <a href="mailto:info@solunes.com" class="text-primary"> info&#64;solunes.com</a></span> </p>
  </div>
 </div>

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.6607849784596!2d-70.5789382!3d-33.406013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf26bdce8dcb%3A0x86ac59fedd4b3d26!2sAlonso%20de%20C%C3%B3rdova%205045%2C%207561057%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sbo!4v1732672043966!5m2!1ses-419!2sbo"
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade" height="450" width="100vw"></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->