import { Component, OnInit } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
  standalone:true,
  imports:[ScrollToModule, FeatherModule, SharedModule]
})

/***
 * Sass Components
 */
export class TermsComponent implements OnInit {

   // Set Topbar Option
  /* option peges */
  navClass = 'nav-dark';
  Menuoption = 'job';
  Settingicon = false;


  constructor() { }

  ngOnInit(): void {
  }
}
